type BlockSettings = {
    hidden: boolean;
    position: number;
    sectionPosition: number;
};

export const useBlock = () => {
    const blockState = inject('block-state', {
        block: reactive<BlockSettings>({
            hidden: false,
            position: 0,
            sectionPosition: 0,
        }),
        element: ref<HTMLElement | undefined>(),
    });
    provide('block-state', blockState);

    return blockState;
};
